<template>
  <div class="loginContainer gotop">
    <headPageNew ref="headPageNew" :activeName="homeActiveName"></headPageNew>
    <carousel />
    <div class="main">
      <div class="entrance">
        <div class="entranceItem">
          <h3>培训管理</h3>
          <p>参培单位及主管部门</p>
          <el-button type="primary" plain round @click="loginShow(false)">立即登录<i class="el-icon-right el-icon--right"></i></el-button>
        </div>
        <div class="entranceItem">
          <h3>在线选课</h3>
          <p>参培单位</p>
          <el-button type="primary" plain round @click="onCourseCenter">挑选好课<i class="el-icon-right el-icon--right"></i></el-button>
        </div>
        <div class="entranceItem">
          <h3>招生报名</h3>
          <p>培训机构及评价机构 </p>
          <el-button type="primary" plain round @click="loginShow(false)">立即登录<i class="el-icon-right el-icon--right"></i></el-button>
        </div>
        <div class="entranceItem">
          <h3>在线学习</h3>
          <p>参培学员</p>
          <el-button type="primary" plain round @click="loginShow(true)">立即登录<i class="el-icon-right el-icon--right"></i></el-button>
        </div>
        <div class="entranceItem">
          <h3>资源管理</h3>
          <p>资源方</p>
          <el-button type="primary" plain round @click="loginShow(false)">立即登录<i class="el-icon-right el-icon--right"></i></el-button>
        </div>
      </div>
      <div class="commonTitle">
        机构智能化在线工具
      </div>
      <div class="onlineTools">
        <div class="onlineToolsItem" @click="clickOnlineTools(1)">
          <div class="onlineToolsItemInfo">
            <h3>招生报名轻松搞定</h3>
            <img src="../../assets/loginIndexNew/onlineToolsImg1.png" alt="" style="width: 271px;height: 180px;">
          </div>
          <div class="onlineToolsItemBtns">
            <span>专属网校</span>
            <span>快速报名</span>
            <span>在线审核</span>
            <span>资料打包</span>
          </div>
        </div>
        <div class="onlineToolsItem" @click="clickOnlineTools(2)">
          <div class="onlineToolsItemInfo">
            <h3>海量课程高效培训</h3>
            <img src="../../assets/loginIndexNew/onlineToolsImg2.png" alt="" style="width: 271px;height: 180px;">
          </div>
          <div class="onlineToolsItemBtns">
            <span>技能提升</span>
            <span>安全生产</span>
            <span>工伤预防</span>
            <span>职业健康</span>
          </div>
        </div>
        <div class="onlineToolsItem" @click="clickOnlineTools(3)">
          <div class="onlineToolsItemInfo">
            <h3>精选题库智能刷题</h3>
            <img src="../../assets/loginIndexNew/onlineToolsImg3.png" alt="" style="width: 271px;height: 180px;">
          </div>
          <div class="onlineToolsItemBtns">
            <span>在线练习</span>
            <span>模拟考试</span>
            <span>错题分析</span>
            <span>在线搜题</span>
          </div>
        </div>
        <div class="onlineToolsItem" @click="clickOnlineTools(4)">
          <div class="onlineToolsItemInfo">
            <h3>云端考试稳定高效</h3>
            <img src="../../assets/loginIndexNew/onlineToolsImg4.png" alt="" style="width: 271px;height: 180px;">
          </div>
          <div class="onlineToolsItemBtns">
            <span>考务编排</span>
            <span>模拟练习</span>
            <span>线上评卷</span>
            <span>证书管理</span>
          </div>
        </div>
      </div>
      <div class="commonTitle">
        企业人力资源管理新模式
      </div>
      <div class="manageModel">
        <div class="manageModelItem" @click="clickManageModel(1)">
          <span class="labelTip">人才集市</span>
          <h3>找到企业所需人才</h3>
          <p>您想找到合适的蓝领工人吗？</p>
          <div class="manageModelImg">
            <img src="../../assets/loginIndexNew/manageModelImg1.png" alt="">
          </div>
          <span class="manageModelBtn" @click.stop="toOnlineService(1)">
            立即咨询<i class="el-icon-right el-icon--right"></i>
          </span>
        </div>
        <div class="manageModelItem" @click="clickManageModel(2)">
          <span class="labelTip labelTip-color1">人力咨询</span>
          <h3>提升人力资源效能</h3>
          <p>如何做好企业绩效考核与奖金分配？</p>
          <div class="manageModelImg">
            <img src="../../assets/loginIndexNew/manageModelImg2.png" alt="">
          </div>
          <span class="manageModelBtn" @click.stop="toOnlineService(2)">
            立即咨询<i class="el-icon-right el-icon--right"></i>
          </span>
        </div>
        <div class="manageModelItem" @click="clickManageModel(3)">
          <span class="labelTip">灵活用工</span>
          <h3>满足兼职用工需求</h3>
          <p>企业零散人员如何有效管理？</p>
          <div class="manageModelImg">
            <img src="../../assets/loginIndexNew/manageModelImg3.png" alt="">
          </div>
          <span class="manageModelBtn" @click.stop="toOnlineService(3)">
            立即咨询<i class="el-icon-right el-icon--right"></i>
          </span>
        </div>
        <div class="manageModelItem" @click="clickManageModel(4)">
          <span class="labelTip labelTip-color2">税收筹划</span>
          <h3>帮助企业降本增效</h3>
          <p>如何做好合理避税与税收筹划？</p>
          <div class="manageModelImg">
            <img src="../../assets/loginIndexNew/manageModelImg4.png" alt="">
          </div>
          <span class="manageModelBtn" @click.stop="toOnlineService(4)">
            立即咨询<i class="el-icon-right el-icon--right"></i>
          </span>
        </div>
      </div>

    </div>
    <div class="invitationBar">
      <h3>安知诚邀各方伙伴合作共赢</h3>
      <span @click="onBusinessCooperation">诚邀合作</span>
    </div>

    <bottomPage />
    <hz />


  </div>
</template>
<script>
import bottomPage from "../wrongAuthenticatePage/bottomPage.vue";
import AES from "../../utils/aes";
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import carousel from "../wrongAuthenticatePage/carousel.vue";
import hz from "../wrongAuthenticatePage/hz.vue";

import { setCookie,delCookie,delAllStorage2 } from "@/utils/common";

export default {
  name: "loginCommon",
  components: {
    hz,
    carousel,
    headPageNew,
    bottomPage
  },
  data() {

    return {
      version: process.env.VUE_APP_VERSION,

      homeActiveName: "",

    };
  },
  created: function () {
    this.$delAllStorage2()
    this.checkbrowser();
    if (this.$route.query.loginShowFlag == "true") {
      let loginShowStu = this.$route.query.loginShowStu == "true"?true:this.$route.query.loginShowStu == "false"?false:''
      console.log(loginShowStu)
      this.$nextTick(()=>{
        if(this.$route.query.loginShowStu == "true"){
          this.$refs.headPageNew.loginShow(loginShowStu,true)
        }else{
          this.$refs.headPageNew.loginShow(loginShowStu)
        }

      })

    }


    if(this.$route.query.name){
      this.homeActiveName = this.$route.query.name
    }else{
      this.homeActiveName = '1'
    }

    // 记录上次登录方式-防止从上一个页面返回来自动回到上一次登录方式
    this.loginMethodXY = this.$route.params.loginMethodXY || 1;
  },
  methods: {
    // 打开登录
    loginShow(xy){
      this.$refs.headPageNew.loginShow(xy)
    },
    // 点击智能化工具记录埋点
    clickOnlineTools(type){
      let gatherInfoParams = {
        contentId: '',// 内容的系统id
        contentName: '',// 内容名称
        moduleCode: 'HOME',
        /* 模块标识

          HOME - 首页,
          COURSE - 课程推广页,
          DIGITAL_SERVICE - 数字服务,
          BUSINESS_COOPERATION - 商务合作

        */
        positionCode: 'INTELLIGENT_TOOLS',
        /* 位置标识
          【PC_HR - HOME】
            NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
            INTELLIGENT_TOOLS - 智能化工具
            HR_PATTERN  - 人力资源新模式]

          【PC_HR - COURSE】
            CATEGORY - 分类
            COURSE - 课程
        */
        topNum: type// 顺序编号
      }
      if(type == 1){
        gatherInfoParams.contentName = "招生报名轻松搞定"
      } else if (type == 2){
        gatherInfoParams.contentName = "海量课程高效培训"
      } else if (type == 3){
        gatherInfoParams.contentName = "精选题库智能刷题"
      } else if (type == 4){
        gatherInfoParams.contentName = "云端考试稳定高效"
      }

      this.$gatherInfo(gatherInfoParams)
      // 除了第二个模块都跳转到数字服务、第二个跳转到课程资源
      if(type == 2){
        this.onCourseCenter()
      }else{
        this.onDigitalServices()
      }
    },
    // 课程资源
    onCourseCenter() {
      this.$router.push({
        path: "/wrongAuthenticatePage/courseCenter",
      });
    },
    // 数字服务
    onDigitalServices() {
      this.$router.push({
        path: "/wrongAuthenticatePage/digitalServices",
      });
    },
    // 人力资源管理记录埋点
    clickManageModel(type){
      let gatherInfoParams = {
        contentId: '',// 内容的系统id
        contentName: '',// 内容名称
        moduleCode: 'HOME',
        /* 模块标识

          HOME - 首页,
          COURSE - 课程推广页,
          DIGITAL_SERVICE - 数字服务,
          BUSINESS_COOPERATION - 商务合作

        */
        positionCode: 'HR_PATTERN',
        /* 位置标识
          【PC_HR - HOME】
            NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
            INTELLIGENT_TOOLS - 智能化工具
            HR_PATTERN  - 人力资源新模式]

          【PC_HR - COURSE】
            CATEGORY - 分类
            COURSE - 课程
        */
        topNum: type// 顺序编号
      }
      if(type == 1){
        gatherInfoParams.contentName = "人才集市"
      } else if (type == 2){
        gatherInfoParams.contentName = "管理咨询"
      } else if (type == 3){
        gatherInfoParams.contentName = "灵活用工"
      } else if (type == 4){
        gatherInfoParams.contentName = "降本增效"
      }

      this.$gatherInfo(gatherInfoParams)
    },


    // 课程资源
    // onCourseCenter() {
    //   this.$router.push({
    //     path: "/wrongAuthenticatePage/courseCenter",
    //   });
    // },
    // 在线客服
    toOnlineService(type){
      this.clickManageModel(type)
      window.open("https://108736.kefu.easemob.com/webim/im.html?configId=301c075f-fa61-4fee-8968-771a086d1390&language=zh-CN")
    },
    // 商务合作
    onBusinessCooperation() {
      this.$router.push({
        path: "/wrongAuthenticatePage/businessCooperation",
      });
    },

// 判断浏览器
checkbrowser() {
  if (navigator.userAgent.indexOf("Edge") >= 0) {
    //微软edge

    alert(
      "抱歉！暂时还无法兼容您当前的浏览器！为了能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
    );
  } else if (
    navigator.userAgent.indexOf("Chrome") >= 0 &&
    navigator.userAgent.indexOf("WebKit") >= 0
  ) {
    //谷歌内核
  } else if (navigator.userAgent.indexOf("AppleWebKit") >= 0) {
    //mac谷歌内核
  } else {
    alert(
      "抱歉！暂时还无法兼容您当前的浏览器！为了您能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
    );
  }
},


},
  // watch: {
  //   $route: {
  //     handler: function (val, oldVal) {
  //       console.log(val.query)
  //       if (val.query.loginShowFlag == true) {
  //         this.$refs.headPageNew.loginShow(val.query.loginShowStu)
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
  // beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>

.loginContainer{
  margin-top: 62px;
  background-color: #fff!important;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0!important }

}
.main{
  width: 1440px;
  margin: -96px auto 0;
  padding-bottom: 60px;
  position: relative;
  z-index: 50;
  //background: #000;
}
.entrance{
  display: flex;
  align-items: center;

  .entranceItem{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    height: 180px;
    border: 1px solid #fff;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    border-radius: 12px;
    transition: 0.5s;
    &:hover{
      background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.7);
    }
    &:before{
      content: '';
      position: absolute;
      width: 57px;
      height: 57px;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: url("../../assets/loginIndexNew/homeTipBg1.png");
      background-size: cover;
    }
    &:after{
      content: '';
      position: absolute;
      width: 81px;
      height: 81px;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-image: url("../../assets/loginIndexNew/homeTipBg2.png");
      background-size: cover;
    }
    //&:before{
    //  content: '';
    //  position: absolute;
    //  top: 50%;
    //  transform: translateY(-50%);
    //  right: 0;
    //  width: 1px;
    //  height: 60px;
    //  background-color: #DDD;
    //}
    &:last-child{
      //&:before{
      //  background-color: transparent;
      //}
      margin-right: 0;
    }
    h3{
      font-size: 18px;
      color: #333;
    }
    p{
      margin-top: 8px;
      margin-bottom: 19px;
      font-size: 14px;
      color: #666;
    }
    /deep/.el-button--primary.is-plain{
      transition: 0.5s;
      border-color: #999;
      color: #666;
      background-color: #fff;
      .el-icon--right{
        color: #2878FF;
      }
      &:hover{
        border-color: #2878FF;
        color: #fff;
        background-color: #2878FF;
        .el-icon--right{
          color: #fff;
        }
      }
    }
  }
}
.commonTitle{
  color: #333;
  line-height: 1.5;
  font-weight: bold;
  margin: 40px 0 40px;
  text-align: center;
  font-size: 30px;
}

.onlineTools{
  display: flex;
  flex-wrap: wrap;
  .onlineToolsItem{
    width: calc(50% - 15px);
    height: 272px;
    transition: 0.5s;
    background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
    border-radius: 12px 12px 12px 12px;
    padding: 10px 10px 0 40px;
    border: 1px solid transparent;
    &:nth-child(1),&:nth-child(2){
      margin-bottom: 30px;
    }
    &:nth-child(1),&:nth-child(3){
      margin-right: 30px;
    }
    &:hover{
      background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
      border: 1px solid #fff;
      .onlineToolsItemInfo{
        img{
          opacity: 1;
        }
      }
    }
    .onlineToolsItemInfo{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3{
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }
      img{
        transition: 0.5s;
        opacity: 0.8;
      }
    }
    .onlineToolsItemBtns{

      margin: 10px 42px 0 12px;
      display: flex;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-left: 30px;
        height: 42px;
        font-size: 16px;
        color: #333;
        border-radius: 8px;
        background-color: #fff;
        transition: 0.5s;
        cursor: pointer;
        &:nth-child(1){
          margin-left: 0;
        }
        &:hover{
          box-shadow: 0px 3px 20px 1px rgba(0,0,0,0.1);
          color: #2878FF;
        }
      }
    }
  }
}


.manageModel{
  display: flex;
  .manageModelItem{
    position: relative;
    flex: 1;
    height: 512px;
    border-radius: 12px;
    background: linear-gradient(0deg, #F3F5F8 0%, #F9F9F9 100%);
    box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    border: 1px solid #FFFFFF;
    padding: 0 30px;
    transition: 0.5s;
    z-index: 1;
    cursor: pointer;
    &:hover{
      z-index: 5;
      transform: scale(1.1,1.1);
      background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
      box-shadow: 0px 10px 30px 1px #E0E4EE;
      border: 1px solid transparent;
      .manageModelImg{
        img{
          opacity: 1;
        }
      }
      .manageModelBtn{
        color: #fff;
        background: #2878FF;
      }
    }
    .labelTip{
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #fff;
      border-radius: 12px 0px 12px 0px;
      background: #2878FF;
      &.labelTip-color1{
        background: #FC987E;
      }
      &.labelTip-color2{
        background: #7ADDE4;
      }
    }
    .manageModelImg{
      display: flex;
      justify-content: center;
      height: 180px;
      margin-top: 60px;
      img{
        width: 180px!important;
        height: 180px!important;
        opacity: 0.8;
        transition: 0.5s;
      }
    }
    .manageModelBtn{
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 58px;
      background: #fff;
      color: #333;
      font-size: 16px;
      border-radius: 0px 0px 12px 12px;
      transition: 0.5s;
    }
    &>h3{
      font-size: 24px;
      font-weight: bold;
      color: #333;
      margin-top: 90px;
    }
    &>p{
      font-size: 16px;
      color: #333;
      margin-top: 16px;
    }

  }
}
.invitationBar{
  text-align: center;
  background-image: url("../../assets/loginIndexNew/blueBgImg.png");
  background-position: center;
  background-size: cover;
  height: 260px;
  h3{
    padding-top: 73px;
    font-size: 36px;
    color: #fff;
  }
  span{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    width: 130px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: 0.5s;
    &:hover{
      box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.2);
      color: #2878ff;
    }
  }
}
@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .onlineTools .onlineToolsItem .onlineToolsItemInfo h3{
    font-size: 22px;
  }
  .manageModel .manageModelItem{
    padding: 0 20px
  }
  .manageModel .manageModelItem > h3{
    font-size: 22px;
  }

}

</style>
